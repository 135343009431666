import parse from "html-react-parser";

/* Replaces selected Characters
 * Return JSX-Objects
 */

export function convertCharacter(text: string) {
  //console.log("Roh Text:",JSON.stringify(text));
  return parse(
    text
      .replaceAll("::li::", "<li>")
      .replaceAll("::ul::", "<ul>")
      .replaceAll("::liEnd::", "</li>")
      .replaceAll("::ulEnd::", "</ul>")
      .replaceAll("::br::", "<br/>") // wenn /n verwendet wird muss br tag entfernt da sonst doppelte Absatz replaceAll("\n", "<br />")
      .replaceAll("::strong::", "<strong>")
      .replaceAll("::strongEnd::", "</strong>")
      .replaceAll("müba", "<span class='company-name'>müba</span>")
      .replaceAll("::h3::", "<h3>")
      .replaceAll("::h3End::", "</h3>")
      .replaceAll("::p::", "<p>")
      .replaceAll("::pEnd::", "</p>")
      .replaceAll("::aprivacyPolicy::", '<a href="/privacypolicy">')
      .replaceAll("::aprivacyPolicyEnd::", "</a>")
      .replaceAll(
        "::mailtoPrivacyPolicy::",
        '<a href="mailto:datenschutz@mueba.de">'
      )
      .replaceAll("::mailtoPrivacyPolicyEnd::", "</a>")
      .replaceAll("&lt;", "<")
      .replaceAll("&quot;", '""')
      .replaceAll("&#039;", "'")
      .replaceAll("&gt;", ">")
  );
}
