import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";

export function Impressum() {
  const { t } = useTranslation();
  return (
    <main>
      <section>
        <Container>
          <h2>{t("impressum")}</h2>
          <h3>{t("impressum.tmg")}</h3>
          <br />
          <h3>Müller & Baum GmbH & Co. KG</h3>
          <p>Birkenweg 52 </p>
          <p> DE-59846 Sundern-Hachen</p>
          <p>
            {t("phone")}: <a href="tel:029358010">+49 (2935) 801-0</a>{" "}
          </p>
          <p>
            {t("email")}: <a href="mailto:service@mueba.de">service@mueba.de</a>
          </p>
          <p>{t("impressum.content")} {" "} Claudia Hartnig</p>
          <p>https://www.mueba.de</p>

          <h3>{t("impressum.register")}</h3>
          <p>{t("impressum.location")} Arnsberg</p>
          <p>HRA 1008</p>
          <p>HRB 614</p>
          <br />

          <h3>GmbH {t("impressum.managing")}</h3>
          <p> Joachim Müller</p>
          <p>Daniel Mimberg</p>
          <h3>{t("impressum.vatnumber")}</h3>
          <p>DE 123885504</p>
          <br />
          <h3>{t("impressum.security.hint")}</h3>
          <p>
            {t("impressum.security.text")}
          </p>
          <br />
          <h3>{t("impressum.copyright.hint")}</h3>
          <p>
            {t("impressum.copyright.text")}
          </p>
          <p>
            {t("impressum.copyright.text.two")}
          </p>
          <br />
          <h3>{t("impressum.general.information")}</h3>
          <p>
            {t("impressum.general.text")}
          </p>
          <p>
            {t("impressum.general.text.two")}
          </p>
        </Container>
      </section>
    </main>
  );
}
