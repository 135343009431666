import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Link from "components/routing/Link";
import Button from "react-bootstrap/Button";
import { ProductCard } from "components/card/ProductCard";
import { ContactForm } from "components/contact/ContactForm";
import { ContactPersonSearch } from "pages/ContactPerson/ContactPersonSearch";
import ReactPlayer from "react-player/youtube";

//Images
import aboutUs from "assets/verwaltung-mueba.png";
import firmenlauf from "assets/firmenlauf.png";
import newButton from "assets/logos/newButtonMobile.png";
import { useEffect, useState } from "react";
import { ArticleCategoryInterface } from "types/articleCategory";
import { fetchArticleCategories } from "services/articleCategories";
import { toast } from "react-toastify";
import { CompanyInformationCardList } from "components/card/CompanyInformationCard";
import { CompanyInformationCard } from "types/companyInformation";
import { useTranslation } from "react-i18next";
import { convertCharacter } from "services/convertCharacter";

export function Home() {
  const { t } = useTranslation();

  const companyInformations: CompanyInformationCard[] = [
    {
      title: "",
      count: 165,
      detail: t("home.employees"),
    },
    {
      title: "",
      count: 5,
      detail: t("home.locations"),
    },
  ];

  const [articleCategories, setArticleCategories] = useState<
    ArticleCategoryInterface[]
  >([]);
  const [cookies, setCookies] = useState(false);

  useEffect(() => {
    getArticleCategories();
  }, []);

  const getArticleCategories = async () => {
    try {
      const articleCategories: ArticleCategoryInterface[] =
        await fetchArticleCategories();
      setArticleCategories(articleCategories);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : (err as string);
      toast.error(errorMessage);
    }
  };

  return (
    <main>
      <section className="background-image"></section>
      <section className="section-secondary">
        <Container>
          <p className="text-center opener-text" data-aos="fade-down">
            {t("greeting")}
            <span className="company-name"> müba!</span>{" "}
          </p>{" "}
          <p className="text-center">
            {convertCharacter(t("home.openertext"))}
          </p>
          <div
            className="d-flex text-center justify-content-center align-items-end d-none d-xl-flex"
            data-aos="fade-up"
          >
            <h2 style={{ marginLeft: "200px" }}>
              {t("home.deliveryProgramm")}
            </h2>
            <Link to={{ name: "DownloadsNewItems" }}>
              <Image
                src={newButton}
                style={{
                  height: "120px",
                  objectFit: "contain",
                }}
                className="new-button"
              />
            </Link>
          </div>
          <div className="d-block d-xl-none text-center" data-aos="fade-up">
            <Link to={{ name: "DownloadsNewItems" }}>
              <Image
                src={newButton}
                style={{
                  height: "70px",
                  width: "120px",
                  objectFit: "contain",
                }}
                className="new-button-mobile"
              />
            </Link>
            <h2>{t("home.deliveryProgramm")}</h2>
          </div>
          <hr />
          <Row>
            {articleCategories.map((ac) => (
              <Col md="6" data-aos="fade-up" key={ac.id}>
                <ProductCard productCard={ac} />
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section>
        <Row className="justify-items-center align-items-center margin-right-zero">
          <Col md="6">
            <Container style={{ maxWidth: "30rem" }}>
              <div className="about-us " data-aos="fade-up">
                <h1 className="text-center">{t("nav.about")}</h1>
                <CompanyInformationCardList
                  companyInformations={companyInformations}
                />
                <p>{convertCharacter(t("home.aboutUs"))}</p>
                <Link to={{ name: "About" }}>
                  <Button variant="danger" className="mt-4 text-center">
                    {t("home.thisIsmuebaButton")}
                  </Button>
                </Link>
              </div>
            </Container>
          </Col>
          <Col md="6" data-aos="fade-down">
            <Image src={aboutUs} />
          </Col>
        </Row>
      </section>
      <section className="section-fullscreen">
        <Container>
          <Row className="align-items-center">
            {cookies ? (
              <>
                <Col md="8" className="d-none d-xl-block" data-aos="fade-down">
                  <ReactPlayer
                    height={432}
                    width={768}
                    volume={0}
                    playing={false}
                    controls
                    url="https://www.youtube.com/watch?v=eCjxyxq9ZA0"
                  />
                </Col>
                <Col md="4" className="d-xl-none" data-aos="fade-down">
                  <ReactPlayer
                    height={198}
                    width={352}
                    volume={0}
                    playing={false}
                    controls
                    url="https://www.youtube.com/watch?v=eCjxyxq9ZA0"
                  />
                </Col>
              </>
            ) : (
              <>
                <Col md="6" className="text-center" data-aos="fade-down">
                  <h3>{t("home.thirdPartyService")}</h3>
                  <p>
                    <a href="https://static.googleusercontent.com/media/www.google.com/de//intl/de/policies/privacy/google_privacy_policy_de.pdf">
                      Google Privacy Policies
                    </a>
                  </p>
                  <Button
                    variant="danger"
                    className="m-2"
                    onClick={() => setCookies((cookies) => !cookies)}
                  >
                    {t("home.button.accept")}
                  </Button>
                </Col>
              </>
            )}
            <Col md="4" className="text-center" data-aos="fade-up">
              <h1>{t("home.movie.portrait")}</h1>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-secondary ">
        <Container>
          <Row>
            <Col md="4" data-aos="fade-down">
              <h2>{convertCharacter(t("home.team.title"))}</h2>
              <p>
                {t("home.team.text")}
                <a className="none-decoration" href="mailto:karriere@mueba.de">
                  karriere@mueba.de
                </a>
              </p>
              <Link to={{ name: "Career" }}>
                <Button variant="danger">{t("career.button")}</Button>
              </Link>
            </Col>
            <Col md="8" data-aos="fade-up">
              <Image src={firmenlauf} className="response-image" />
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <ContactPersonSearch />
        </Container>
      </section>
      <section className="section-secondary ">
        <Container data-aos="fade-up">
          <ContactForm />
        </Container>
      </section>
    </main>
  );
}
