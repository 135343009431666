import { useEffect, useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import {
  fetchNewPdfDownloads,
  fetchPdfById,
  fetchPdfDownloads,
} from "services/downloads";
import { AttachmentGroups } from "types/downloads";
import { AttachmentGroupList } from "components/Downloads/AttachmentGroupList";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";


// Manuals Attachment_group_id = 
export function DownloadsManuals() {
  const { t } = useTranslation();

  const [manuals, setManuals] = useState<AttachmentGroups[]>([]);
  const fetchManuals = async () => {
    try {
      const manuals: AttachmentGroups[] = await fetchPdfById(4);
      setManuals(manuals);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : (err as string);
      toast.error(errorMessage);
    }
  }

  useEffect(() => {

    fetchManuals();
  }, []);

  return (
    <main>
      <section className="section-secondary">
        <Container>
          {manuals.length <= 0 ? (
            <>
              <h1 id="manuals" className="text-center d-none d-xl-block">{t("attachments.manuals")}</h1>
              <h2 id="manuals" className="text-center d-xl-none">{t("attachments.manuals")}</h2>
              <hr />
            </>
          ) : (

            <>

              <h1 id="manuals" className="text-center d-none d-xl-block">{t("attachments.manuals")}</h1>
              <h2 id="manuals" className="text-center d-xl-none">{t("attachments.manuals")}</h2>
              <hr />
              <b>{manuals[0]?.names[0]?.title}</b>
              <AttachmentGroupList attachmentGroups={manuals} />
            </>

          )}

        </Container>
      </section>
    </main>
  );
}