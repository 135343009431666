import Link from "components/routing/Link";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { useTranslation } from "react-i18next";
import facebook from "assets/icons/square-facebook.svg";
import instagram from "assets/icons/square-instagram.svg";
import linkedin from "assets/icons/linkedin.svg";
import youtube from "assets/icons/square-youtube.svg";

export function AppFooter() {
  const { t } = useTranslation();
  return (
    <footer>
      {/* Footer for Desktop View*/}
      <Container className="d-none d-md-block">
        <Row>
          <Col md="8">
            <span className="mt-3 mx-2">müba @ 2025. All Rights Reserved</span>
            <Link className="mx-3" to={{ name: "PrivacyPolice" }}>
              <span className="text-white">{t("footer.privacy")}</span>
            </Link>
            <Link className="mx-2" to={{ name: "Impressum" }}>
              <span className="text-white">{t("footer.imprint")}</span>
            </Link>
            <Link className="mx-2" to={{ name: "AGB" }}>
              <span className="text-white">{t("footer.agb")}</span>
            </Link>
          </Col>
          <Col md="4" className="d-flex justify-content-end">
            <a
              href="https://www.facebook.com/people/m%C3%BCba-Bauger%C3%A4te-Bauger%C3%BCste/61557663857907/"
              target="_blank"
              className="mx-3"
              rel="noreferrer"
            >
              <Image
                src={facebook}
                height={25}
                className="contact-icon-image"
              />
            </a>
            <a
              href="https://www.instagram.com/mueba_deutschland/"
              target="_blank"
              className="mx-3"
              rel="noreferrer"
            >
              <Image
                src={instagram}
                height={25}
                className="contact-icon-image"
              />
            </a>
            <a
              href="https://de.linkedin.com/company/m%C3%BCba-m%C3%BCller-baum-gmbh-co-kg"
              target="_blank"
              className="mx-3"
              rel="noreferrer"
            >
              <Image
                src={linkedin}
                height={25}
                className="contact-icon-image"
              />
            </a>
            <a
              href="https://www.youtube.com/watch?v=eCjxyxq9ZA0"
              target="_blank"
              className="mx-3"
              rel="noreferrer"
            >
              <Image src={youtube} height={25} className="contact-icon-image" />
            </a>
          </Col>
        </Row>
      </Container>
       {/* Footer for mobile View*/}
      <Container className="d-block d-md-none">
        <Row>
          <Col md="3" className="mt-2">
            <span className="mt-3">müba @ 2025. All Rights Reserved</span>
          </Col>
          <Col md="3" className="mt-2">
            <Link to={{ name: "PrivacyPolice" }}>
              <span className="text-white">{t("footer.privacy")}</span>
            </Link>
          </Col>
          <Col md="3" className="mt-2 ">
            <Link to={{ name: "Impressum" }}>
              <span className="text-white">{t("footer.imprint")}</span>
            </Link>
          </Col>
          <Col md="3" className="mt-2">
            <Link to={{ name: "AGB" }}>
              <span className="text-white">{t("footer.agb")}</span>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col md="3" sm="2" xs="3" className="my-2">
            <a
              href="https://www.facebook.com/people/m%C3%BCba-Bauger%C3%A4te-Bauger%C3%BCste/61557663857907/"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={facebook} height={25} width={25} />
            </a>
          </Col>
          <Col md="3" sm="2" xs="3" className="my-2">
            <a
              href="https://www.instagram.com/mueba_deutschland/"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={instagram} height={25} width={25} />
            </a>
          </Col>
          <Col md="3" sm="2" xs="3" className="my-2">
            <a
              href="https://de.linkedin.com/company/m%C3%BCba-m%C3%BCller-baum-gmbh-co-kg"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={linkedin} height={25} width={25} />
            </a>
          </Col>
          <Col md="3" sm="2" xs="3" className="my-2">
            <a
              href="https://www.youtube.com/watch?v=eCjxyxq9ZA0"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={youtube} height={25} width={25} />
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
