import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import { ArticleProduct } from "../../types/articleProduct";
import ReactPlayer from "react-player";
import Container from "react-bootstrap/Container";

import altImage from "assets/ProductCard/altImage.png";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { fetchProductDownload } from "services/articleProducts";
import { useTranslation } from "react-i18next";
import ProductBreadcrumb from "components/ProductBreadcrumb/ProductBreadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { convertCharacter } from "services/convertCharacter";
import parse from "html-react-parser";

export interface ProductDetailsListItemProps {
  productDetail: ArticleProduct;
}

export function ProductDetailsListItem({
  productDetail,
}: ProductDetailsListItemProps) {
  const downloadAttachmentsAvailable = productDetail.attachments.filter(
    (pd) => !pd.filepath.endsWith(".mp4")
  );

  const { t, i18n } = useTranslation();
  //to adapt floating numbers
  const language = i18n.language;

  const getProductDownload = async (id: number) => {
    try {
      const blob: Blob = await fetchProductDownload(id);
      const url = window.URL.createObjectURL(blob);

      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = productDetail.number + ".pdf";
      anchor.click();

      window.URL.revokeObjectURL(url);
      anchor.remove();
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : (err as string);
      toast.error(errorMessage);
    }
  };

  const convertHtmlCharacter = (text: string | number) => {
    if (typeof text === "number") {
      return text;
    }
    return text
      .replaceAll("&lt;", "<")
      .replaceAll("&quot;", '"')
      .replaceAll("&#039;", "'")
      .replaceAll("&gt", ">")
      .replaceAll("&circ;", "^")
      .replaceAll("&tilde;", "~")
      .replaceAll("&ndash;", "–")
      .replaceAll("&amp;", "&");
  };

  {/* function to make floating-point number with comma or dot depending on the language */ }
  const formatFloatNumbers = (value: string | number, language: string) => {
    const commaRegex = /([0-9]+),([0-9]+)/g;
    const dotRegex = /([0-9]+)\.([0-9]+)/g;

    {/* if value contains comma or dot Pattern */ }
    if (/[0-9]+,[0-9]+/.test(value.toString()) || /[0-9]+\.[0-9]+/.test(value.toString())) {

      {/* check if german */ }
      const formattedNumber = language === "de"
        ? value.toString().replace(dotRegex, '$1,$2')  // comma for german
        : value.toString().replace(commaRegex, '$1.$2');// dot for all other languages
      return formattedNumber;
    }
    {/* if pattern is not found return */ }
    return value;
  };

  function convertLangTextCharacter(text: string) {

    return parse(
      text
        .replaceAll("::li::", "<li>")
        .replaceAll("::ul::", "<ul>")
        .replaceAll("::liEnd::", "</li>")
        .replaceAll("::ulEnd::", "</ul>")

        .replaceAll("::strong::", "<strong>")
        .replaceAll("::strongEnd::", "</strong>")
        .replaceAll("müba", "<span class='company-name'>müba</span>")
        .replaceAll("::h3::", "<h3>")
        .replaceAll("::h3End::", "</h3>")
        .replaceAll("::p::", "<p>")
        .replaceAll("::pEnd::", "</p>")
        .replaceAll("\n", "<br />")
        .replaceAll("::aprivacyPolicy::", '<a href="/privacypolicy">')
        .replaceAll("::aprivacyPolicyEnd::", "</a>")
        .replaceAll(
          "::mailtoPrivacyPolicy::",
          '<a href="mailto:datenschutz@mueba.de">'
        )
        .replaceAll("::mailtoPrivacyPolicyEnd::", "</a>")
        .replaceAll("&lt;", "<")
        .replaceAll("&quot;", '""')
        .replaceAll("&#039;", "'")
        .replaceAll("&gt;", ">")
    );
  }

  return (
    <>
      <Container className="pt-3">
        <ProductBreadcrumb />
      </Container>
      <Container fluid>
        <Row className="mt-5">
          <Col md={5}>
            <Image
              style={{ height: 700, objectFit: "contain" }}
              src={
                !productDetail.images.length
                  ? altImage
                  : productDetail.images[0]?.src
              }
            />
          </Col>
          <Col md={4}>
            {/* Description not used yet*/}
            <h2 className="d-none d-xl-block">
              {formatFloatNumbers(productDetail.descriptions[0].title, language)}
            </h2>
            <h2 className="d-sm-block d-xl-none" style={{ fontSize: "19px" }}>
              {formatFloatNumbers(productDetail.descriptions[0].title, language)}
            </h2>
            {productDetail.descriptions[0].description !== "" ? (
              <span className="">
                {formatFloatNumbers(
                  convertHtmlCharacter(
                    productDetail.descriptions[0]?.description // Product Description can contain floating numbers
                  ), language
                )}
              </span>
            ) : (
              <></>
            )}

            {/*  article LangText*/}
            {/* Check if article has langtext data and if the text is not an empty string*/}
            {productDetail.langtexte?.length > 0 && productDetail.langtexte[0].langtext !== "" ? (
              <span className="">
                {
                  convertLangTextCharacter(
                    productDetail.langtexte[0]?.langtext
                  )
                }
                <p></p>  {/* Abstand Text Tabelle */}
              </span>

            ) : (
              <></>
            )}


            {/* Product Info Table */}
            <Table striped bordered hover className="mt-2">


              <tbody>
                {/* Article Number */}
                <tr>
                  <td>{t("product.articlenumber")}</td>
                  <td>{productDetail.number}</td>
                </tr>

                {/* EAN, Weight , Material etc, 
                all Elements starting with Material are handled through convertFunction for exmample Ø17 mm / Ø27 mm 
                and not as value like EAN and Weight */}
                {productDetail.properties.map((productDetailProp) => (
                  <tr key={productDetailProp.id}>
                    <td>
                      {productDetailProp.names[0] ? (
                        convertHtmlCharacter(productDetailProp.names[0]?.name)
                      ) : (
                        <></>
                      )}
                    </td>
                    {/* EAN-Number, Weight in kg , Material = galvanized steel etc */}
                    <td>
                      {productDetailProp.value !== null
                        ? formatFloatNumbers(
                          productDetailProp.value?.value,
                          language
                        )
                        : ""}{" "}
                      {productDetailProp.unit !== null ? (
                        typeof productDetailProp.unit.names[0].name ===
                          "string" &&
                          productDetailProp.unit.names[0].name.startsWith(
                            "https"
                          ) ? (
                          <a
                            href={productDetailProp.unit.names[0].name}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            {productDetailProp.unit.names[0].name}{" "}
                          </a>
                        ) : (
                          formatFloatNumbers(
                            convertHtmlCharacter(
                              productDetailProp.unit.names[0].name
                            ),
                            language
                          )
                        )
                      ) : (
                        <span></span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Row>

              <Col md="12" lg="4">
                {/* Datasheet button, product detail table als pdf*/}
                <Button
                  variant="danger"
                  onClick={() => getProductDownload(productDetail.id)}
                >
                  {t("product.button")}
                </Button>
              </Col>
              <Col md="12" lg="8">
                {/* list with other downloadable pdf's*/}
                {downloadAttachmentsAvailable.length > 0 ? (
                  <span>Downloads:</span>
                ) : (
                  <></>
                )}
                <ul>
                  {downloadAttachmentsAvailable.map((pd) => (
                    <li
                      className="attachment"
                      key={pd.filepath}
                      onClick={() => window.open(pd.filepath)}
                    >
                      <FontAwesomeIcon icon={faDownload} /> {pd.names[0].title}
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </Col>
          <Col lg="3" md="4" sm="8" xs="8" className="align-self-center ">
            {productDetail.attachments
              .filter((pd) => pd.filepath.endsWith(".mp4"))
              .map((pd) => (
                <ReactPlayer
                  width="99%"
                  height="54%"
                  url={pd.filepath}
                  key={pd.filepath}
                  config={{
                    file: {
                      attributes: {
                        type: "video/mp4",
                        crossOrigin: "true",
                      },
                    },
                  }}
                  controls
                />
              ))}
          </Col>
        </Row>

      </Container>
    </>
  );
}
